import {Environment, Network, RecordSource, Store} from 'relay-runtime';

const url = process.env.NODE_ENV === "development" ? "https://api-server-1.goaffpro.com/":"https://api.goaffpro.com/"
const access_token = process.env.NODE_ENV === "development" ? "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZmZpbGlhdGVfaWQiOjIsImlhdCI6MTYyODU3MjgxNH0.rRtl3_zkCN2e06WeV5u3yd3DiPVpYMWgMVaDZ1m0sqY" : localStorage.getItem('access_token')
async function fetchGraphQL(text, variables) {
    return fetch(url+'graphql', {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query: text,
            variables,
        }),
    }).then((response)=>response.json()).catch((e)=>{
        console.log(e)
        return {}
    });
}

async function fetchRelay(params, variables) {
    return fetchGraphQL(params.text, variables);
}

// Export a singleton instance of Relay Environment configured with our network function:
export default new Environment({
    network: Network.create(fetchRelay),
    store: new Store(new RecordSource()),
});
