/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type OauthInstructionsFragment_instructions$ref = any;
export type AppDetailPageQueryVariables = {|
  id?: ?number
|};
export type AppDetailPageQueryResponse = {|
  +myApps: $ReadOnlyArray<{|
    +name: string,
    +appId: number,
    +website: ?string,
    +client_id: string,
    +client_secret: string,
    +approvedScopes: $ReadOnlyArray<string>,
    +approvedRedirectUrls: $ReadOnlyArray<string>,
    +termsPageUrl: ?any,
    +privacyPageUrl: ?any,
    +appIcon: ?any,
    +$fragmentRefs: OauthInstructionsFragment_instructions$ref,
  |}>
|};
export type AppDetailPageQuery = {|
  variables: AppDetailPageQueryVariables,
  response: AppDetailPageQueryResponse,
|};
*/


/*
query AppDetailPageQuery(
  $id: Int
) {
  myApps(appId: $id) {
    ...OauthInstructionsFragment_instructions
    name
    appId
    website
    client_id
    client_secret
    approvedScopes
    approvedRedirectUrls
    termsPageUrl
    privacyPageUrl
    appIcon
    id
  }
}

fragment OauthInstructionsFragment_instructions on App {
  client_id
  client_secret
  approvedScopes
  approvedRedirectUrls
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "appId",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "website",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "client_id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "client_secret",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approvedScopes",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approvedRedirectUrls",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "termsPageUrl",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "privacyPageUrl",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appIcon",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppDetailPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "App",
        "kind": "LinkedField",
        "name": "myApps",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OauthInstructionsFragment_instructions"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppDetailPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "App",
        "kind": "LinkedField",
        "name": "myApps",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eee4a0200682a47c9502a7815d1d8b3f",
    "id": null,
    "metadata": {},
    "name": "AppDetailPageQuery",
    "operationKind": "query",
    "text": "query AppDetailPageQuery(\n  $id: Int\n) {\n  myApps(appId: $id) {\n    ...OauthInstructionsFragment_instructions\n    name\n    appId\n    website\n    client_id\n    client_secret\n    approvedScopes\n    approvedRedirectUrls\n    termsPageUrl\n    privacyPageUrl\n    appIcon\n    id\n  }\n}\n\nfragment OauthInstructionsFragment_instructions on App {\n  client_id\n  client_secret\n  approvedScopes\n  approvedRedirectUrls\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a084278f806dafebb053e667c478b364';

module.exports = node;
