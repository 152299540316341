import React from 'react';
import PropTypes from 'prop-types'

function Spinner({size}){
    return <div className={`spinner-border text-primary`}  role="status"
                style={{width:size+"rem", height:size+"rem"}}>
        <span className="sr-only">Loading...</span>
    </div>
}
Spinner.propTypes = {
    size: PropTypes.number
}

Spinner.defaultProps = {
    size: 2
}

export default Spinner;
