import React from 'react';
import useQuery from "../components/hooks/useQuery";
function DemoRedirectComponent(props) {
    const {
        code
    } = useQuery()
    function getAccessToken(){
        //exchange for access token

    }
    return (
        <div className={"min-vh-100 bg-light"}>
            <div className="container">
                <div className="py-4"/>
                <h1 className="text-center">Your platform</h1>

                <div className="card">
                    <div className="card-header">Callback</div>
                    <div className="card-body">
                        <pre>{
                            JSON.stringify({
                                code
                            }, null, 2)
                        }</pre>

                        <hr/>
                        <h2>Relevant HTTP Calls</h2>
                        <hr/>
                        <h4>Retrieve Access Token</h4>
                        <div className={"bg-light rounded p-4"}>
                        <div className="text-monospace">
                        <span><span className="text-success">POST</span> https://api.goaffpro.com/oauth/access_token</span>
                        <div>content-type: <span className={"text-info"}>application/json</span></div>
                        </div>
                        <pre>{
                             JSON.stringify({
                               "client_id":"",
                               "client_secret":"",
                               "redirect_uri":"",
                               "code":code
                             }, null, 2)
                        }
                        </pre>
                        </div>
                        <hr/>
                        <h4>Login to account</h4>
                        <div className={"bg-light rounded p-4"}>
                        <div className="text-monospace">
                        <span><span className="text-success">POST</span> https://api.goaffpro.com/v1/user/login</span>
                        <div>content-type: <span className={"text-info"}>application/json</span></div>
                        </div>

                        <pre>{
                            JSON.stringify({
                                "email":"",
                                "password":"",
                            }, null, 2)
                        }
                        </pre>
                        </div>
                        <hr/>
                        <h4>Get list of connected sites</h4>
                        <div className={"bg-light rounded p-4"}>
                            <div className="text-monospace">
                                <span><span className="text-primary">GET</span> https://api.goaffpro.com/v1/user/sites</span>
                                <div>Authorization:  <span className={"text-info"}>Bearer authTokenFromLoginEndpoint</span></div>
                            </div>
                        </div>
                        <h4>Set Commission rate</h4>
                        <div className={"bg-light rounded p-4"}>

                        <div className="text-monospace">
                        <span><span className="text-success">POST</span> https://api.goaffpro.com/v1/user/commissions?site_id=7</span>
                        <div>x-goaffpro-access-token: <span className={"text-info"}>access token retrieved above</span></div>
                        <div>Authorization:  <span className={"text-info"}>Bearer authTokenFromLoginEndpoint</span></div>
                        <div>content-type: <span className={"text-info"}>application/json</span></div>
                        </div>
                        <pre>{
                            JSON.stringify({
                                "commission_type":"percentage",
                                "commission_value":"20",
                            }, null, 2)
                        }
                        </pre>
                        </div>
                        <hr/>
                        <h4>Mark payments as paid</h4>
                        <div className={"bg-light rounded p-4"}>
                        <div className="text-monospace">

                        <span><span className="text-success">POST</span> https://api.goaffpro.com/v1/user/payouts?site_id=7</span>
                        <div>x-goaffpro-access-token: access token retrieved above</div>
                        <div>Authorization: Bearer authTokenFromLoginEndpoint</div>
                        <div>content-type: <span className={"text-info"}>application/json</span></div>
                        </div>
                        <pre>

                        {
                            JSON.stringify({
                                "amount":"10",
                                "currency":"USD",
                                "note":"Payment note",
                                "payment_method":"paypal", //or cash, bank_transfer,
                            }, null, 2)
                        }
                        </pre>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
}

export default DemoRedirectComponent;
