import React from 'react';
import Label from "./Label";


function FormGroup ({children, label, required, info}){
    return <div className="form-group">
        {
            label && <><Label title={label}/>{required && <small className={"ml-1 text-danger"}>*</small>}</>
        }
        {children}
        {
            info && <small className={"mt-1 text-muted d-block"}>{info}</small>
        }
    </div>
}
export default FormGroup;
