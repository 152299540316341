import React from 'react';
import {Link} from "react-router-dom";
import ListOfApps from "./components/ListOfApps";

function MyApps(props) {
    return (
        <>
            <div className="d-flex align-items-start justify-content-between">
                <h1>My Apps</h1>
                <Link to={"/new"} className="btn btn-primary">Create new app</Link>
            </div>
            <ListOfApps />
        </>
    );
}

export default MyApps;
