import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import useQuery from "../../components/hooks/useQuery";
import {useMutation} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Spinner from "../../components/form/Spinner";

const refreshTokenMutation = graphql`
    mutation LoginWithTokenMutation($accessToken: String!){
        refreshAccessToken(accessToken: $accessToken){
            ...on AccessToken{
                accessToken
            }
            ...on Error{
                code
                message
            }
        }
    }
`


export default function LoginWithToken(props){
    const [error, setError] = useState(null)
    const q = useQuery();
    const [commit] = useMutation(refreshTokenMutation);
    const {accessToken} = useParams()
    useEffect(()=>{
        if(accessToken){
            commit({
                variables:{
                    accessToken
                },
                onCompleted({refreshAccessToken}){
                    if(refreshAccessToken.accessToken){
                        localStorage.setItem('access_token', refreshAccessToken.accessToken);
                        if(q.redirect_to){
                            window.location = q.redirect_to;
                        }else {
                            window.location = "/"
                        }
                    }else{
                        setError('Invalid token');
                        //history.replace('/auth/login?'+ new URLSearchParams({redirect_to: q.redirect_to || ''}))
                    }
                }
            })

        }
    },[accessToken])

    return (
        <div className={"bg-light"}>
            <div className={"container-fluid "}>
                <div className={"row justify-content-center min-vh-100"} style={{paddingTop:'80px'}}>
                    <div className={"col-md-6 col-lg-4"}>
                        <div className="text-center" style={{paddingTop:100}}>
                            {
                                error ? <p className="text-danger">{error}</p> :
                                    <Spinner/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
