import React from 'react';
import TopNavBar from "./TopNavBar";

function DashboardContainer({children}) {
    if(process.env.NODE_ENV !== "development" && !localStorage.getItem('access_token')){
        return   <main className={"min-vh-100 d-flex flex-column"}>
            <TopNavBar />
            <div className="d-flex flex-column bg-light" style={{flex:1}}>
                <div className="container" style={{flex:1}}>
                    <main className={"py-4 text-center rounded mt-4 bg-white border"}>
                        <h1>Not authorized</h1>
                        <p>You are not authorized to access this page. </p>
                        <p><a href={"https://goaffpro.com/login"}>LOGIN HERE</a></p>
                    </main>
                </div>
                <footer className={"bg-dark p-4 text-white text-monospace text-center"}>
                    <small>Copyright © {new Date().getFullYear()} GOAFFPRO. All rights reserved.</small>
                </footer>
            </div>
        </main>
    }
    return (
        <>
            <main className={"min-vh-100 d-flex flex-column"}>
                <TopNavBar />
                <div className="d-flex flex-column bg-light" style={{flex:1}}>
                    <div className="container" style={{flex:1}}>
                        <main className={"py-4"}>
                            {
                                children
                            }
                        </main>
                    </div>
                    <footer className={"bg-dark p-4 text-white text-monospace text-center"}>
                        <small>Copyright © {new Date().getFullYear()} GOAFFPRO. All rights reserved.</small>
                    </footer>
                </div>
            </main>
        </>
    );
}

export default DashboardContainer;
