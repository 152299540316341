/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type OauthInstructionsFragment_instructions$ref: FragmentReference;
declare export opaque type OauthInstructionsFragment_instructions$fragmentType: OauthInstructionsFragment_instructions$ref;
export type OauthInstructionsFragment_instructions = {|
  +client_id: string,
  +client_secret: string,
  +approvedScopes: $ReadOnlyArray<string>,
  +approvedRedirectUrls: $ReadOnlyArray<string>,
  +$refType: OauthInstructionsFragment_instructions$ref,
|};
export type OauthInstructionsFragment_instructions$data = OauthInstructionsFragment_instructions;
export type OauthInstructionsFragment_instructions$key = {
  +$data?: OauthInstructionsFragment_instructions$data,
  +$fragmentRefs: OauthInstructionsFragment_instructions$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OauthInstructionsFragment_instructions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "client_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "client_secret",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approvedScopes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approvedRedirectUrls",
      "storageKey": null
    }
  ],
  "type": "App",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'cf528000fa256388f77b66ee72b87322';

module.exports = node;
