import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

const Button = function (props){
    const cls = "btn "+props.className;
    const {loading, saved, title} = props;
    const btnProps = {...props};
    delete btnProps.loading;
    delete btnProps.saved
    return (
        <button {...btnProps} disabled={props.disabled || loading || saved} className={cls}>
            {
                loading &&  <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"/>
            }
            {!loading && !saved && props.children }
            {
                saved && <span>Saved</span>
            }
            {
                title && <FormattedMessage id={title} />
            }
        </button>
    )
}

Button.propTypes = {
    className: PropTypes.string,
    loading: PropTypes.bool,
    onClick: PropTypes.func.isRequired
}
export default Button;
