import React from 'react';
import {FiArrowLeft} from "react-icons/fi";
import {useHistory} from 'react-router-dom'
function BackButton({to}) {
    const history = useHistory();
    function goBack(){
        if(to){
            history.replace(to)
        }else{
            history.goBack()
        }
    }
    return (
        <button className={"btn px-0"} onClick={goBack}>
            <FiArrowLeft /> <span>Go back</span>
        </button>
    );
}

export default BackButton;
