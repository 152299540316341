import React, {useEffect} from 'react';
import graphql from "babel-plugin-relay/macro";
import Skeleton from "react-loading-skeleton";
import {Link} from "react-router-dom";

const {useQueryLoader, usePreloadedQuery} = require('react-relay');
const query = graphql`
    query ConnectedSitesQuery($id: Int) {
        myApps(appId: $id) {
            connectedSites {
                site {
                    name
                }
            }
        }
    }
`

function ConnectedSites({appId}) {
    const [queryRef, loader] = useQueryLoader(query, null);
    useEffect(() => {
        loader({
            id:Number(appId)
        })
    }, [appId])

    if(!queryRef) { return null }

    return (
        <React.Suspense fallback={<Skeleton count={24} />}>
            <SiteList queryReference={queryRef} appId={appId}/>
        </React.Suspense>
    );
}

const SiteList = ({ queryReference, appId }) => {
    const data = usePreloadedQuery(query, queryReference)
    if(!data) {
        return null
    } else {
        const app = data.myApps[0].connectedSites

        if(app.length === 0)
            return (
                <>
                <div style={{display: "grid", justifyContent: "center", height: '55vh', alignItems: "center"}}>
                    <h2 style={{marginBottom: '-15rem'}}>No Connected Sites</h2>
                    <p style={{textAlign: "center", margin: '-5rem 0 0 -2rem'}}><Link to= {`/details/${appId}/instructions`}>Learn how to connect your app with Goaffpro</Link></p>
                </div>
                </>
            )

        return (
            <div className={"bg-white border-right border-left border-bottom"}>
                <ul className="list-group list-group-flush">
                    {
                        app.map((item)=>{
                            return  <li className="list-group-item">
                                {item.site.name}
                            </li>
                        })
                    }
                </ul>
            </div>
        )

    }
}

export default ConnectedSites;
