import React, {useMemo} from 'react';
import FormGroup from "../../components/form/FormGroup";
import {useFragment} from "react-relay";
import graphql from 'babel-plugin-relay/macro'
function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

function OauthInstructionsFragment({app}){
    const data = useFragment(graphql`
        fragment OauthInstructionsFragment_instructions on App{
            client_id
            client_secret
            approvedScopes
            approvedRedirectUrls
        }
    `,app)
    const {client_id, client_secret, approvedRedirectUrls, approvedScopes} = data
    return <RenderOauthInstructionsComponent client_id={client_id}
                                      client_secret={client_secret}
                                      approvedRedirectUrls={approvedRedirectUrls}
                                      approvedScopes={approvedScopes}/>
}

export function RenderOauthInstructionsComponent({client_id, client_secret, approvedRedirectUrls, approvedScopes}) {
    const redir = approvedRedirectUrls[0]
    const scope = approvedScopes.join(",")
    const randomString = useMemo(()=>{
        return makeid(10)
    },[])
    const code = "abc";
    const url = new URL('https://app.goaffpro.com/oauth')
    url.searchParams.append("client_id", client_id)
    url.searchParams.append('scope', scope)
    url.searchParams.append('redirect_uri', redir)
    const finalRedirectUri = new URL(redir)
    finalRedirectUri.searchParams.append("code", randomString)


    return (
        <div>
            <h2>How to use oAuth</h2>
            <hr/>
            <h3>Step 1</h3>
            <p>Build the oAuth access URL with help of your <code>client_id</code>, <code>scope</code> and <code>redirect_uri</code></p>
            <p>The base URL of app authorization flow is <code>https://app.goaffpro.com/oauth/</code></p>
            <p>Example:</p>
            <p className={"text-monospace bg-light p-2 border rounded"}>
                https://app.goaffpro.com/oauth?client_id=<code>client_id</code>&scope=<code>scope</code>&redirect_uri=<code>redirect_uri</code>
            </p>
            <p>Final URL will look something like below</p>
            <FormGroup>
                <input type="text" className="form-control" value={url.href}/>
            </FormGroup>
            <p>Send the user to the above URL</p>
            <p className={"alert alert-info"}>The app will now do the requisite steps to get the goaffpro integration set up in the merchant's store.</p>
            <h3>Step 2</h3>
            <p>Once the app is setup in the merchant's store, the merchant will be redirected back to the specified <code>redirect_uri</code> with an additional <code>code</code> query parameter</p>
            <p>Example</p>
            <p className={"bg-light p-2 border rounded"}>{finalRedirectUri.href}</p>
            <p>You will need to extract this <code>code</code> query parameter and use this to retrieve the <code>access_token</code> to make API calls to the merchant's store</p>
            <div className="row">
                <div className="col-md-6">
                    <FormGroup label={"HTTP Method"}>
                        <div className={"text-info text-monospace"}>POST</div>
                    </FormGroup>
                    <FormGroup label={"URL"}>
                        <div className={"text-info text-monospace"}>https://api.goaffpro.com/oauth/access_token</div>
                    </FormGroup>
                    <FormGroup label={"Headers"}>
                        <table className="table table-bordered table-sm">
                            <thead>
                                <tr>
                                    <td>Header</td>
                                    <td>Value</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Content-Type</td>
                                    <td>application/json</td>
                                </tr>
                            </tbody>
                        </table>
                    </FormGroup>
                    <FormGroup label={"Request Body"}>
                           <pre>{
                               JSON.stringify({
                                   client_id,
                                   client_secret,
                                   "grant_type":"authorization_code",
                                   "code":code
                               }, null, 2)
                           }</pre>

                    </FormGroup>
                </div>
                <div className="col-md-6">
                    <p className={"text-monospace bg-light p-2 rounded border"}>
                        <span><span className={"text-success"}>POST</span> https://api.goaffpro.com/oauth/access_token</span>
                        <br/>Content-Type: <span style={{color:'orange'}}>application/json</span>
                        <br/>
                        <br/>
                        <pre className={"mb-0"}>{
                            JSON.stringify({
                                client_id,
                                client_secret,
                                "grant_type":"authorization_code",
                                "code":code
                            }, null, 2)
                        }</pre>
                    </p>
                    <p>Response</p>
                    <p className="text-monospace bg-light p-2 rounded border">
                <pre className={"mb-0"}>{
                    JSON.stringify({access_token:'access_token'}, null, 2)
                }</pre>
                    </p>
                </div>
            </div>
            <hr/>
            <h3>Step 3</h3>
            <p>Use the retrieved <code>access_token</code> to make API calls to the merchant store</p>
            <p><a href={"https://api.goaffpro.com/docs/admin"} title={"Read API documentation"} target={"_blank"}>API Documentation</a></p>
        </div>
    );
}

export default OauthInstructionsFragment;
