/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Result = "ERROR" | "OK" | "%future added value";
export type AppDeleteButtonMutationVariables = {|
  appId: number
|};
export type AppDeleteButtonMutationResponse = {|
  +deleteApp: ?Result
|};
export type AppDeleteButtonMutation = {|
  variables: AppDeleteButtonMutationVariables,
  response: AppDeleteButtonMutationResponse,
|};
*/


/*
mutation AppDeleteButtonMutation(
  $appId: Int!
) {
  deleteApp(appId: $appId)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "appId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "appId",
        "variableName": "appId"
      }
    ],
    "kind": "ScalarField",
    "name": "deleteApp",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppDeleteButtonMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppDeleteButtonMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "559f7a6492c1e152b34c888cb08b4844",
    "id": null,
    "metadata": {},
    "name": "AppDeleteButtonMutation",
    "operationKind": "mutation",
    "text": "mutation AppDeleteButtonMutation(\n  $appId: Int!\n) {\n  deleteApp(appId: $appId)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a76d395d32e14cd7c1aefb1d3ed16c9b';

module.exports = node;
