import React, {useState} from 'react';
import FormGroup from "../components/form/FormGroup";

function DemoComponent(props) {
    const base = "http://localhost:3000/oauth"
    const [query, setQuery] = useState({
        client_id:localStorage.getItem('demo_client_id'),
        redirect_uri:localStorage.getItem('demo_redirect_uri') || 'http://localhost:3005/demo/redirect',
        scope:'user_write_commissions,user_write_payouts',
        user_email:'anujtenani@gmail.com',
        admin_email:'merchant@store.com',
        admin_name:'Admin name'
    })
    const uri = new URL(base);
    uri.searchParams.set('client_id', query.client_id);
    uri.searchParams.set('redirect_uri',query.redirect_uri)
    uri.searchParams.set('scope', query.scope);
    uri.searchParams.set('user_email', query.user_email);
    uri.searchParams.set('admin_email', query.admin_email);
    uri.searchParams.set('admin_name', query.admin_name);

    return (
        <div className={"min-vh-100 bg-light"}>
            <div className="container">
                <div className="pt-4"/>
                <h1 className="text-center">Your platform</h1>
                <div className="card">
                    <div className="card-header">oAuth 2 Demo</div>
                    <div className="card-body">
                        <FormGroup label={"Client ID"}>
                            <input type="text" value={query.client_id} onChange={(e)=>{
                                localStorage.setItem('demo_client_id', e.target.value)
                                setQuery((q)=>{
                                    return {
                                        ...q,
                                        client_id: e.target.value
                                    }
                                })
                            }} className="form-control"/>
                        </FormGroup>
                        <pre>
                        {
                            JSON.stringify({
                                base,
                                query,
                                uri:uri.href,
                            }, null, 2)
                        }
                    </pre>
                    </div>
                </div>
                <div className="text-center mt-4">
                    <a href={uri.href} className="btn btn-secondary">Connect with Goaffpro</a>
                </div>
            </div>
        </div>
    );
}

export default DemoComponent;
