import React, { useState, useEffect } from 'react';
import BackButton from "../components/layout/BackButton";
import {useHistory, useParams} from "react-router-dom";
import { useMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import Button from "../components/form/Button";
import Skeleton from 'react-loading-skeleton'
import AppDeleteButton from "./components/AppDeleteButton";
import AppCrudForm from "./components/AppCrudForm";
const {useQueryLoader, usePreloadedQuery} = require('react-relay');
const q = graphql`
    query UpdateAppQuery($appId: Int!) {
        myApps(appId: $appId) {
            appId
            name
            website
            approvedScopes
            approvedRedirectUrls
            termsPageUrl
            privacyPageUrl
            appIcon
        }
    }
`
const mutation = graphql`
    mutation UpdateAppMutation($appId: Int!,$data: AppInput!){
        updateApp(appId:$appId,data: $data){
            id
            name
            website
            approvedRedirectUrls
            approvedScopes 
            termsPageUrl
            privacyPageUrl
            appIcon
        }
    }
`

function UpdateApp(props) {
    const {appId}  = useParams()
    const [queryRef,loader]= useQueryLoader(q ,null)
    useEffect(() => {
        loader({
            appId: Number(appId)
        })
       }, [appId])
    if(!queryRef){
       return null
   }
    return (
        <>
            <BackButton />
            <React.Suspense fallback={<LoadingSkeleton />}>
                <RenderApp queryRef={queryRef} />
            </React.Suspense>
        </>
    );
}

function LoadingSkeleton(){
    return <>
        <h1>Update <Skeleton count={1} /> </h1>
        <div className="card">
            <div className="card-body">
               <Skeleton height={30} />
            </div>
        </div>
    </>
}

function RenderApp({queryRef}){
    const {appId}  = useParams()
    const data = usePreloadedQuery(q,queryRef);
    if(!data) return null
    const app = data.myApps.find(o => String(o.appId)===String(appId))
    return   <>
        <h1>Update {app.name}</h1>
        <div className="card">
            <div className="card-body">
                <Forms data={app} />
           </div>
        </div>
    </>
}

function Forms({data}) {
    const [commit, submitting] = useMutation(mutation);
    const [appInput, setAppInput] = useState(data)

    const {appName, approvedScopes, approvedRedirectUrls, website, appIcon, termsPageUrl, privacyPageUrl} = appInput;
    const canSubmit = appName && approvedScopes && approvedRedirectUrls
    const {appId}  = useParams()
    const [successMessage, setSuccessMessage] = useState(null)

    const onFormSubmit = (e) => {
        if (e) {
            e.preventDefault()
            e.stopPropagation()
        }
        setSuccessMessage(null)
        commit({
           variables: {
               appId:parseInt(appId),
               data: {
                   appIcon:appIcon,
                   name: appName,
                   website: website,
                   approvedRedirectUrls: approvedRedirectUrls,
                   approvedScopes: approvedScopes,
                   termsPageUrl: termsPageUrl,
                   privacyPageUrl: privacyPageUrl
               }
           },
            onCompleted(response){
               setSuccessMessage('Change applied successfully')
            }
        })
    }
    const history = useHistory();
    const onChange = key => e =>{
        setAppInput((prevState)=>{
            return {
                ...prevState,
                [key]:e.target.value
            }
        })
    }
return (
    <>
    <form onSubmit={onFormSubmit}>
        <AppCrudForm onChange={onChange} appInput={appInput} />
        {
            successMessage ? <p className="text-success">{successMessage}</p> : null
        }
        <Button onClick={onFormSubmit} disabled={!canSubmit} loading={submitting} className={"btn btn-primary"}>Submit</Button>
        <div className="text-right">
        <hr/>
            <AppDeleteButton appId={appId} onDeleted={()=>{
            return history.goBack()
        }} className={"text-danger btn-sm"}>Delete app</AppDeleteButton>
        </div>
    </form>
    </>
)
}
export default UpdateApp;
