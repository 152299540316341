/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConnectedSitesQueryVariables = {|
  id?: ?number
|};
export type ConnectedSitesQueryResponse = {|
  +myApps: $ReadOnlyArray<{|
    +connectedSites: $ReadOnlyArray<{|
      +site: {|
        +name: string
      |}
    |}>
  |}>
|};
export type ConnectedSitesQuery = {|
  variables: ConnectedSitesQueryVariables,
  response: ConnectedSitesQueryResponse,
|};
*/


/*
query ConnectedSitesQuery(
  $id: Int
) {
  myApps(appId: $id) {
    connectedSites {
      site {
        name
        id
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "appId",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConnectedSitesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "App",
        "kind": "LinkedField",
        "name": "myApps",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AppConnectedSite",
            "kind": "LinkedField",
            "name": "connectedSites",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "site",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConnectedSitesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "App",
        "kind": "LinkedField",
        "name": "myApps",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AppConnectedSite",
            "kind": "LinkedField",
            "name": "connectedSites",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "site",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5c0295150fc7c048b056385867697310",
    "id": null,
    "metadata": {},
    "name": "ConnectedSitesQuery",
    "operationKind": "query",
    "text": "query ConnectedSitesQuery(\n  $id: Int\n) {\n  myApps(appId: $id) {\n    connectedSites {\n      site {\n        name\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ad638c82548c76af6f0741d5198023c3';

module.exports = node;
