/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateAppQueryVariables = {|
  appId: number
|};
export type UpdateAppQueryResponse = {|
  +myApps: $ReadOnlyArray<{|
    +appId: number,
    +name: string,
    +website: ?string,
    +approvedScopes: $ReadOnlyArray<string>,
    +approvedRedirectUrls: $ReadOnlyArray<string>,
    +termsPageUrl: ?any,
    +privacyPageUrl: ?any,
    +appIcon: ?any,
  |}>
|};
export type UpdateAppQuery = {|
  variables: UpdateAppQueryVariables,
  response: UpdateAppQueryResponse,
|};
*/


/*
query UpdateAppQuery(
  $appId: Int!
) {
  myApps(appId: $appId) {
    appId
    name
    website
    approvedScopes
    approvedRedirectUrls
    termsPageUrl
    privacyPageUrl
    appIcon
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "appId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "appId",
    "variableName": "appId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "website",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approvedScopes",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approvedRedirectUrls",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "termsPageUrl",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "privacyPageUrl",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appIcon",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAppQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "App",
        "kind": "LinkedField",
        "name": "myApps",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAppQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "App",
        "kind": "LinkedField",
        "name": "myApps",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8e2557a6a97d1ea0279af949d40047e9",
    "id": null,
    "metadata": {},
    "name": "UpdateAppQuery",
    "operationKind": "query",
    "text": "query UpdateAppQuery(\n  $appId: Int!\n) {\n  myApps(appId: $appId) {\n    appId\n    name\n    website\n    approvedScopes\n    approvedRedirectUrls\n    termsPageUrl\n    privacyPageUrl\n    appIcon\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1d0913ecdf0fcfe38d004eced639009a';

module.exports = node;
