/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AppInput = {|
  name: string,
  website?: ?string,
  approvedScopes: $ReadOnlyArray<string>,
  approvedRedirectUrls: $ReadOnlyArray<string>,
  termsPageUrl?: ?any,
  privacyPageUrl?: ?any,
  appIcon?: ?any,
|};
export type UpdateAppMutationVariables = {|
  appId: number,
  data: AppInput,
|};
export type UpdateAppMutationResponse = {|
  +updateApp: ?{|
    +id: string,
    +name: string,
    +website: ?string,
    +approvedRedirectUrls: $ReadOnlyArray<string>,
    +approvedScopes: $ReadOnlyArray<string>,
    +termsPageUrl: ?any,
    +privacyPageUrl: ?any,
    +appIcon: ?any,
  |}
|};
export type UpdateAppMutation = {|
  variables: UpdateAppMutationVariables,
  response: UpdateAppMutationResponse,
|};
*/


/*
mutation UpdateAppMutation(
  $appId: Int!
  $data: AppInput!
) {
  updateApp(appId: $appId, data: $data) {
    id
    name
    website
    approvedRedirectUrls
    approvedScopes
    termsPageUrl
    privacyPageUrl
    appIcon
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "appId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "data"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "appId",
        "variableName": "appId"
      },
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "App",
    "kind": "LinkedField",
    "name": "updateApp",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "website",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "approvedRedirectUrls",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "approvedScopes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "termsPageUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "privacyPageUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "appIcon",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAppMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAppMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ee8b4ae80a2c5a2daf339d1a13799746",
    "id": null,
    "metadata": {},
    "name": "UpdateAppMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAppMutation(\n  $appId: Int!\n  $data: AppInput!\n) {\n  updateApp(appId: $appId, data: $data) {\n    id\n    name\n    website\n    approvedRedirectUrls\n    approvedScopes\n    termsPageUrl\n    privacyPageUrl\n    appIcon\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f8931ced43d7e7154df89add78e18d9f';

module.exports = node;
