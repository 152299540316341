import React, {useEffect} from 'react';
import {NavLink, Route, Switch, useParams} from 'react-router-dom'
import ConnectedSites from "./components/ConnectedSites";
import AppDetailPage from "./components/AppDetailPage";
import BackButton from "../components/layout/BackButton";
import graphql from 'babel-plugin-relay/macro'
import OauthInstructionsFragment from "./components/OauthInstructionsFragment";
import Skeleton from "react-loading-skeleton";
const {useQueryLoader, usePreloadedQuery} = require('react-relay');

const query = graphql`
    query AppDetailQuery($id: Int) {
        myApps(appId: $id) {
            name
            ...OauthInstructionsFragment_instructions
        }
    }
`

function AppDetail(props) {
    const { appId } = useParams()
    const [queryRef, loader] = useQueryLoader(query, null);

    useEffect(() => {
        loader({
            id: Number(appId)
        })
    }, [appId, loader])

    if(!queryRef) { return null }

    return (
        <>
            <React.Suspense fallback={<Skeleton count={5} />}>
                <RenderApp queryReference={queryRef} appID={appId} />
            </React.Suspense>
        </>
    );
}

const RenderApp = ({ queryReference, appID }) => {
    const data = usePreloadedQuery(query, queryReference)
    if(!data) return <p>Data is loading</p>
    const app = data.myApps[0]
    return (
        <>
            <BackButton to={"/"} />
            <h1>{app.name}</h1>
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <NavLink activeClassName={"active"} exact className="nav-link" to={"/details/"+appID}>Details</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink activeClassName={"active"} className="nav-link" to={"/details/"+appID+"/connected-sites"}>Connected Sites</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink activeClassName={"active"} className="nav-link" to={"/details/"+appID+"/instructions"}>Usage instructions</NavLink>
                </li>
            </ul>
            <div className={"bg-white border-right border-left border-bottom"}>
                <div className="p-4">
            <Switch>
                <Route path={"/details/"+appID+"/connected-sites"} component={()=><ConnectedSites appId={appID} />} />
                <Route path={"/details/"+appID+"/instructions"} component={()=><OauthInstructionsFragment app={app} />}/>
                <Route component={AppDetailPage} />
            </Switch>
                </div>
            </div>
        </>
    )
}



export default AppDetail;
