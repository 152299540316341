/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LoginWithTokenMutationVariables = {|
  accessToken: string
|};
export type LoginWithTokenMutationResponse = {|
  +refreshAccessToken: {|
    +accessToken?: ?any,
    +code?: string,
    +message?: string,
  |}
|};
export type LoginWithTokenMutation = {|
  variables: LoginWithTokenMutationVariables,
  response: LoginWithTokenMutationResponse,
|};
*/


/*
mutation LoginWithTokenMutation(
  $accessToken: String!
) {
  refreshAccessToken(accessToken: $accessToken) {
    __typename
    ... on AccessToken {
      accessToken
    }
    ... on Error {
      code
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accessToken"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "accessToken",
    "variableName": "accessToken"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accessToken",
      "storageKey": null
    }
  ],
  "type": "AccessToken",
  "abstractKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "Error",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginWithTokenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "refreshAccessToken",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoginWithTokenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "refreshAccessToken",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "48983d002db4f4d0b5f907b08bd405e6",
    "id": null,
    "metadata": {},
    "name": "LoginWithTokenMutation",
    "operationKind": "mutation",
    "text": "mutation LoginWithTokenMutation(\n  $accessToken: String!\n) {\n  refreshAccessToken(accessToken: $accessToken) {\n    __typename\n    ... on AccessToken {\n      accessToken\n    }\n    ... on Error {\n      code\n      message\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '369730cbf3f3fc7e27d2cd9ae4330358';

module.exports = node;
