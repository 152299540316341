/**
 * @Author Pranav
 */

import React, { useEffect } from 'react';
import graphql from 'babel-plugin-relay/macro'
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'

const { useQueryLoader, usePreloadedQuery } = require('react-relay');
const q = graphql`
    query ListOfAppsQuery {
        myApps{
            appId
            name
            appIcon
        }
    }
`
function ListOfApps(props) {
    const [queryRef, loader]= useQueryLoader(q ,null)

    useEffect(() => {
        loader()
    }, [])

    if (!queryRef) {
        return null
    }
    return (
        <React.Suspense fallback={<Skeleton count={8} />}>
            <ul className="list-group">
                <Listing queryRef={queryRef} />
            </ul>
        </React.Suspense>
    );
}

function Listing({ queryRef }) {
    const data = usePreloadedQuery(q, queryRef)
    return (
        <>{
            data.myApps.length === 0 ? <Empty /> : <>{
                data.myApps.map((item) => {
                    return <Link to={"/details/" + item.appId}
                        className="d-flex align-items-center list-group-item-action list-group-item"
                        key={item.appId}>
                        {
                            item.appIcon ? <img src={item.appIcon} style={{width:32, height:32, objectFit:'cover', borderRadius:32}} className={"mr-2"} alt={"app icon"}/> : null
                        }
                        <span>
                            {item.name}
                        </span>
                    </Link>
                })
            }</>
        }</>)
}

function Empty() {

    return (
        <div className="card">
            <div className="card-body">
                <p className={"text-center"}>You have not yet created any apps.</p>
                <p className={"text-center"}>Create a new app now ?</p>
                <p className={"text-center"}><Link to={"/new"} className={"btn btn-primary"} >Create a new app</Link></p>
            </div>
        </div>
    );
}
export default ListOfApps;
