/**
 * @Author Zahid
 */
import React, {useState} from 'react';
import { useMutation } from 'react-relay'
import BackButton from "../components/layout/BackButton";
import graphql from 'babel-plugin-relay/macro'
import { useHistory } from 'react-router-dom';
import Button from "../components/form/Button";
import AppCrudForm from "./components/AppCrudForm";
const mutation = graphql`
    mutation CreateNewAppMutation($data: AppInput!){
        createApp(data: $data){
            appIcon
            appId
            id
            name
            approvedScopes
            approvedRedirectUrls
            termsPageUrl
            privacyPageUrl
        }
    }
`
function CreateNewApp(props) {
    const [commit, submitting] = useMutation(mutation);
    const [appInput, setAppInput] = useState({
        name:'',
        website:'',
        approvedScopes:[],
        approvedRedirectUrls:[],
        privacyPageUrl:'',
        termsPageUrl:'',
        appIcon:''
    })
    const {name, approvedScopes, approvedRedirectUrls, website, appIcon, termsPageUrl, privacyPageUrl} = appInput;
    const canSubmit = name && approvedScopes && approvedRedirectUrls
    const history = useHistory();
    const onFormSubmit = (e) => {
        if (e) {
            e.preventDefault()
            e.stopPropagation()
        }

        commit({
           variables: {
               data: {
                   appIcon,
                   name,
                   website,
                   approvedScopes,
                   approvedRedirectUrls,
                   termsPageUrl,
                   privacyPageUrl
               }
           },
            updater(store){
               store.invalidateStore()
           },
           onCompleted(data) {
               history.push(`/details/${data.createApp.appId}`)
           }
        })
    }

    const onChange = key => e =>{
        setAppInput((prevState)=>{
            return {
                ...prevState,
                [key]:e.target.value
            }
        })
    }
    return (
        <>
            <BackButton />
            <h1>Create new app</h1>
            <div className="card">
                <div className="card-body">
                    <form onSubmit={onFormSubmit}>
                        <AppCrudForm onChange={onChange} appInput={appInput} />
                        <Button
                            onClick={onFormSubmit}
                            loading={submitting}
                            className={"btn btn-primary"}
                            disabled={!canSubmit}>
                            Submit
                        </Button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default CreateNewApp;
