import React, {useState} from 'react';
import graphql from 'babel-plugin-relay/macro'
import Button from "../../components/form/Button";
import {useMutation} from "react-relay";
import Modal from "../../components/Modal";

const deleteMutation = graphql`
     mutation AppDeleteButtonMutation($appId: Int!) {
        deleteApp(appId: $appId)
     }
`
function AppDeleteButton({appId, className, children, onDeleted}) {
    const [modalVisible, setModalVisible] = useState(false)
    return (
        <>
            <ConfirmDeleteModal
                appId={appId}
                visible={modalVisible} onClose={(result)=>{
                    if(result){
                        onDeleted(result)
                    }
                    setModalVisible(false)
            }}/>
            <Button type={"button"} className={`btn ${className || ''}`} onClick={()=>setModalVisible(true)}>{children}</Button>
        </>
    );
}

function ConfirmDeleteModal({visible, appId, onClose}){
    const [commit, isInFlight] = useMutation(deleteMutation);
    function onConfirmed(e){
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }
        commit({
            variables: {
                appId: parseInt(appId)
            },
            onCompleted(response) {
                if (response.deleteApp === "OK") {
                    onClose(true)
                }
            },
            updater:(store)=>{
                try {
                    store.invalidateStore()
                }catch(e){
                    console.log(e)
                }
            }
        })
    }
    return <Modal
            title={"Delete app ?"}
            visible={visible}
            onCancel={()=>onClose(false)}
            onOk={onConfirmed}
            confirmLoading={isInFlight}
            okText={"Delete"}>
            <p>Are you sure you want to delete this app ? This will delete the app and disconnect all connected sites</p>
        </Modal>
}


export default AppDeleteButton;
