import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import graphql from 'babel-plugin-relay/macro'
import Skeleton from "react-loading-skeleton";

const {useQueryLoader, usePreloadedQuery} = require('react-relay');
const query = graphql`
    query AppDetailPageQuery($id: Int) {
        myApps(appId: $id) {
            ...OauthInstructionsFragment_instructions
            name
            appId
            website
            client_id
            client_secret
            approvedScopes
            approvedRedirectUrls
            termsPageUrl
            privacyPageUrl
            appIcon
        }
    }
`

function AppDetailPage(props) {
    const { appId }  = useParams()
    const [queryRef, loader] = useQueryLoader(query, null);

    useEffect(() => {
        loader({
            id: Number(appId)
        })
    }, [appId])

    if(!queryRef) { return null }

    return (
        <React.Suspense fallback={<Skeleton count={20} />}>
            <AppDetail queryReference={queryRef} />
        </React.Suspense>
    );
}

const AppDetail = ({ queryReference }) => {
    const data = usePreloadedQuery(query, queryReference)
    if(!data) {
        return null
    } else {
        const app = data.myApps[0]
        return (
            <>
              <RenderAppDetail app={app} />
            </>
        )
    }
}

function RenderAppDetail({app}){
    const {appIcon, appId, name, website, client_id, client_secret, approvedScopes, approvedRedirectUrls, termsPageUrl, privacyPageUrl} = app
    return <div>
        <div className="d-flex align-items-start justify-content-between">
            <div className="d-flex mb-4">
                {
                    appIcon ? <img alt={"Application Icon"} src={appIcon} style={{width:100, height:100, objectFit:'cover'}} className={"border rounded p-1"} /> : null
                }
                <h2 className={"ml-4"}>{name}</h2>

            </div>
            <Link to={"/edit/"+appId}>Edit</Link>
        </div>
        <div className="my-4"/>
        <div className="row">
            <div className="col-md-6">
                <div className="card">
                    <div className="card-header">Information</div>
                    <ul className="list-group list-group-flush">
                        <LiItem title={"Client ID"}>
                            <pre>{client_id}</pre>
                        </LiItem>
                        <LiItem title={"Client Secret"}>
                            <pre>{client_secret}</pre>
                        </LiItem>
                        <LiItem title={"Scope"}>
                            <pre>{approvedScopes.join("\n")}</pre>
                        </LiItem>
                        <LiItem title={"Redirect URLs"}>
                            <pre>{approvedRedirectUrls.join("\n")}</pre>
                        </LiItem>
                    </ul>
                </div>
            </div>
            <div className="col-md-6">
                <div className="card">
                    <div className="card-header">App Details</div>
                    <ul className="list-group list-group-flush">
                        <LiItem title={"Website"}>
                            <small>
                                {website ? <a title={"Go to App website"} href={website} target={"_blank"} rel={"noopener noreferrer nofollow"}>{website}</a> : '-'}
                            </small>
                        </LiItem>
                        <LiItem title={"Privacy Policy"}>
                            <small>
                                {privacyPageUrl ? <a title={"Go to Privacy policy page"} href={privacyPageUrl} rel={"noopener noreferrer nofollow"} target={"_blank"}>{privacyPageUrl}</a> : '-'}
                            </small>
                        </LiItem>
                        <LiItem title={"Terms and Conditions"}>
                            <small>
                                {termsPageUrl ? <a title={"Go to Terms and Conditions page"} href={termsPageUrl} rel={"noopener noreferrer nofollow"} target={"_blank"}>{termsPageUrl}</a> : '-'}
                            </small>
                        </LiItem>
                    </ul>
                </div>
            </div>
        </div>

    </div>
}

function LiItem({title, children}){
    return <li className={"list-group-item"}>
        <div className={"text-muted"}><small><strong>{title}</strong></small></div>
        {children}
    </li>
}

const ListItem = ({ title, data }) => {
    return (
        <>
            <h5 className={"ml-1"}>{title}</h5>
            <li className={"list-group-item mb-3"}>{data}</li>
        </>
    )
}

export default AppDetailPage;
