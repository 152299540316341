import React from 'react';
import FormGroup from "../../components/form/FormGroup";
import AppIconUpload from "../../components/form/AppIconUpload";
import TextArea from "react-textarea-autosize";



function AppCrudForm({appInput, onChange}) {
    const {appIcon, termsPageUrl, privacyPageUrl, approvedRedirectUrls, approvedScopes, website, name} = appInput;
    const redirectUrls = approvedRedirectUrls.join("\n");

    return (
        <div>
            <FormGroup label={"App Icon"}>
                <AppIconUpload value={appIcon} onChange={({url})=>{
                    onChange('appIcon')({
                        target:{
                            value: url
                        }
                    })
                }} />
            </FormGroup>
            <div className="row">
                <div className="col-md-6">
                    <FormGroup required label={"Name"} info={"eg. My First App"}>
                        <input
                            type="text"
                            required
                            className={"form-control"}
                            value={name}
                            onChange={onChange('name')}
                        />
                    </FormGroup>

                </div>
                <div className="col-md-6">
                    <FormGroup label={"Website"} info={"eg. https://yourplatform.com"}>
                        <input
                            type="url"
                            className={"form-control"}
                            value={website}
                            onChange={onChange('website')}
                        />
                    </FormGroup>
                </div>
            </div>


            <FormGroup label={"Permission Scopes"} required info={"Choose at lease one permission"}>
                <select
                    value={approvedScopes}
                    className="form-control" multiple onChange={(e)=>{
                    const scopes = []
                    for(let option of e.target.selectedOptions){
                        scopes.push(option.value)
                    }
                    onChange('approvedScopes')({
                        target:{
                            value:scopes
                        }
                    })
                }}>
                    <option value="user_write_commissions">user_write_commissions</option>
                    <option value="user_write_payouts">user_write_payouts</option>
                </select>
            </FormGroup>

            <FormGroup label={"Redirect URLs"} required info={"eg. https://platform.com/oauth/redirect"}>
                <TextArea
                    required
                    className={"form-control"}
                    value={redirectUrls}
                    onChange={(e)=>{
                        onChange('approvedRedirectUrls')({
                            target:{
                                value:e.target.value.split("\n")
                            }
                        })
                    }}
                />
            </FormGroup>
            <div className="row">
                <div className="col-md-6">

                    <FormGroup label={"Terms Page URL"} info={"eg. https://platform.com/terms"}>
                        <input
                            value={termsPageUrl}
                            type="url"
                            className={"form-control"}
                            onChange={onChange('termsPageUrl')}
                        />
                    </FormGroup>

                </div>
                <div className="col-md-6">
                    <FormGroup label={"Privacy Page URL"} info={"eg. https://platform.com/privacy"}>
                        <input
                            className={"form-control"}
                            type="url"
                            value={privacyPageUrl}
                            onChange={onChange('privacyPageUrl')}
                        />
                    </FormGroup>
                </div>
            </div>
            <span className={"text-danger d-block text-right"}>* - Required</span>
        </div>
    );
}

export default AppCrudForm;
