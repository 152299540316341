/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type OauthInstructionsFragment_instructions$ref = any;
export type AppDetailQueryVariables = {|
  id?: ?number
|};
export type AppDetailQueryResponse = {|
  +myApps: $ReadOnlyArray<{|
    +name: string,
    +$fragmentRefs: OauthInstructionsFragment_instructions$ref,
  |}>
|};
export type AppDetailQuery = {|
  variables: AppDetailQueryVariables,
  response: AppDetailQueryResponse,
|};
*/


/*
query AppDetailQuery(
  $id: Int
) {
  myApps(appId: $id) {
    name
    ...OauthInstructionsFragment_instructions
    id
  }
}

fragment OauthInstructionsFragment_instructions on App {
  client_id
  client_secret
  approvedScopes
  approvedRedirectUrls
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "appId",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "App",
        "kind": "LinkedField",
        "name": "myApps",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OauthInstructionsFragment_instructions"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "App",
        "kind": "LinkedField",
        "name": "myApps",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "client_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "client_secret",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "approvedScopes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "approvedRedirectUrls",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "415769b49fc5d7fb1c382a6d6d1cb5c7",
    "id": null,
    "metadata": {},
    "name": "AppDetailQuery",
    "operationKind": "query",
    "text": "query AppDetailQuery(\n  $id: Int\n) {\n  myApps(appId: $id) {\n    name\n    ...OauthInstructionsFragment_instructions\n    id\n  }\n}\n\nfragment OauthInstructionsFragment_instructions on App {\n  client_id\n  client_secret\n  approvedScopes\n  approvedRedirectUrls\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '198f90757157f4284e9f4b5b18f393b7';

module.exports = node;
