import DashboardContainer from "./components/layout/DashboardContainer";
import MyApps from "./pages/MyApps";
import {Route, Switch} from 'react-router-dom'
import CreateNewApp from "./pages/CreateNewApp";
import UpdateApp from "./pages/UpdateApp";
import ConnectedSites from "./pages/components/ConnectedSites";
import LoginWithToken from "./pages/auth/LoginWithToken";
import AppDetail from "./pages/AppDetail";
import {useEffect} from "react";
import KitchenSink from "./KitchenSink";
import DemoComponent from "./pages/DemoComponent";
import DemoRedirectComponent from "./pages/DemoRedirectComponent";

function App() {
  return (
      <Switch>
          <Route path={"/auth/loginWithToken/:accessToken"} component={LoginWithToken} />
          <Route path={"/demo/redirect"} component={DemoRedirectComponent} />
          <Route path={"/demo"} component={DemoComponent} />
          <Route component={Dashboard} />
      </Switch>

  );
}
function Dashboard(){
    return   <DashboardContainer>
                <Switch>
                    <Route path={"/auth/logout"} component={Logout}/>
                    <Route path={"/new"} component={CreateNewApp} />
                    <Route path={"/edit/:appId"} component={UpdateApp} />
                    <Route path={"/details/:appId"} component={AppDetail} />
                    <Route path={"/connected-sites"} component={ConnectedSites}/>
                    {
                        process.env.NODE_ENV === "development" &&
                        <Route path={"/kitchen-sink"} component={KitchenSink}/>
                    }
                    <Route component={MyApps} />
                </Switch>
        </DashboardContainer>
}

function Logout(){
    useEffect(()=>{
       localStorage.removeItem('access_token')
        window.location = "https://goaffpro.com/main"
    },[])
}

export default App;
