import React from 'react';
import GoaffproLogo from "./GoaffproLogo";
import {Link} from "react-router-dom";
import {FiLogOut} from "react-icons/fi";

function TopNavBar(props) {
    return (
        <header style={{height:64}} className={"bg-dark py-2 d-flex align-items-center justify-content-between"}>
            <GoaffproLogo inverted />
            {
                localStorage.getItem('access_token') &&
                <Link to={"/auth/logout"} className={"text-light font-weight-lighter px-2 py-2"}>
                    <FiLogOut style={{width: 20, height: 20}}/>
                </Link>
            }
        </header>
    );
}

export default TopNavBar;
