import React, {useCallback, useState} from 'react';
import {useDropzone} from 'react-dropzone'
import graphql from 'babel-plugin-relay/macro'
import {useMutation} from "react-relay";
import Spinner from "./Spinner";
import {FiPlus} from 'react-icons/fi'
const preSignedMutation = graphql`
    mutation AppIconUploadMutation ($filename: String!){
        getPreSignedUrl(fileName: $filename){
            url
            fields
        }
    }
`
function FileUpload({accept, maxSize, onChange, value}) {
    const [commit] = useMutation(preSignedMutation)
    const [loading, setLoading] = useState(false)
    const onDrop = useCallback(acceptedFiles => {
        const file = acceptedFiles[0];
        setLoading(true)
        commit({
            variables:{
                filename:file.name
            },
            onCompleted({getPreSignedUrl}){
                const {url, fields} = getPreSignedUrl;
                const formData = new FormData();
                Object.keys(fields).forEach((item)=>{
                    formData.append(item, fields[item])
                })
                formData.append("file", file)
                fetch(url, {
                    method:'POST',
                    body: formData
                }).then((data)=>data.status === 204)
                    .then((result)=>{
                        setLoading(false)
                        onChange({url: result ? `https://${fields.bucket}/${fields.key}` : null})
                })
            }
        })

    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: accept || 'image/jpeg, image/png, image/jpg',
        maxFiles:1,
        maxSize: maxSize || 5 * 1024 * 1024,
        multiple:false,
    })

    return (
        <div {...getRootProps()} style={{width:100, height:100, borderStyle:'dashed !important'}} className={`border rounded ${isDragActive ? 'bg-light':''}`}>
            <input {...getInputProps()} />
            <div className="p-2"  style={{width:100, height:100}} >
                {
                    loading ? <div className={"h-100 w-100 d-flex align-items-center justify-content-center"}><Spinner/></div> : <div className={"h-100 w-100 d-flex align-items-center justify-content-center"}>
                        {
                            value ? <img alt={"App Icon"}
                                         src={value}
                                         style={{borderRadius:4, width: '100%', height: '100%', objectFit: 'cover'}}/>:  <>{
                                isDragActive ?
                                    <span className={"text-center"}>Drop the file</span> :
                                    <span><FiPlus className={"text-secondary"} style={{width:64, height:64}} /></span>
                            }</>
                        }

                    </div>
                }
            </div>
        </div>
    );
}

export default FileUpload;
