/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AppIconUploadMutationVariables = {|
  filename: string
|};
export type AppIconUploadMutationResponse = {|
  +getPreSignedUrl: {|
    +url: any,
    +fields: any,
  |}
|};
export type AppIconUploadMutation = {|
  variables: AppIconUploadMutationVariables,
  response: AppIconUploadMutationResponse,
|};
*/


/*
mutation AppIconUploadMutation(
  $filename: String!
) {
  getPreSignedUrl(fileName: $filename) {
    url
    fields
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filename"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "fileName",
        "variableName": "filename"
      }
    ],
    "concreteType": "S3PreSignedURL",
    "kind": "LinkedField",
    "name": "getPreSignedUrl",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fields",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppIconUploadMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppIconUploadMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d799c1d75d81e75e9dcc90973c8ee00c",
    "id": null,
    "metadata": {},
    "name": "AppIconUploadMutation",
    "operationKind": "mutation",
    "text": "mutation AppIconUploadMutation(\n  $filename: String!\n) {\n  getPreSignedUrl(fileName: $filename) {\n    url\n    fields\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5c38200491afa1589c2d63027539ba97';

module.exports = node;
