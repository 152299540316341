/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AppInput = {|
  name: string,
  website?: ?string,
  approvedScopes: $ReadOnlyArray<string>,
  approvedRedirectUrls: $ReadOnlyArray<string>,
  termsPageUrl?: ?any,
  privacyPageUrl?: ?any,
  appIcon?: ?any,
|};
export type CreateNewAppMutationVariables = {|
  data: AppInput
|};
export type CreateNewAppMutationResponse = {|
  +createApp: ?{|
    +appIcon: ?any,
    +appId: number,
    +id: string,
    +name: string,
    +approvedScopes: $ReadOnlyArray<string>,
    +approvedRedirectUrls: $ReadOnlyArray<string>,
    +termsPageUrl: ?any,
    +privacyPageUrl: ?any,
  |}
|};
export type CreateNewAppMutation = {|
  variables: CreateNewAppMutationVariables,
  response: CreateNewAppMutationResponse,
|};
*/


/*
mutation CreateNewAppMutation(
  $data: AppInput!
) {
  createApp(data: $data) {
    appIcon
    appId
    id
    name
    approvedScopes
    approvedRedirectUrls
    termsPageUrl
    privacyPageUrl
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "data"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "App",
    "kind": "LinkedField",
    "name": "createApp",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "appIcon",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "appId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "approvedScopes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "approvedRedirectUrls",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "termsPageUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "privacyPageUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateNewAppMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateNewAppMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "54ccef19ddfffc92fcb6aad1ef47f984",
    "id": null,
    "metadata": {},
    "name": "CreateNewAppMutation",
    "operationKind": "mutation",
    "text": "mutation CreateNewAppMutation(\n  $data: AppInput!\n) {\n  createApp(data: $data) {\n    appIcon\n    appId\n    id\n    name\n    approvedScopes\n    approvedRedirectUrls\n    termsPageUrl\n    privacyPageUrl\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '054a7935508c966bbacacb3ff84231bc';

module.exports = node;
